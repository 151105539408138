/* You can add global styles to this file, and also import other style files */
@import "./common-variables.less";


body,body .p-component{
  font-family:segoe-regular !important;
  margin:0px !important;
  background-color: #fff;
  .p-toast{
    .p-toast-message{
      margin: 0 !important;
    }
  }
}
.navbar-toggler-icon{
  display: none;
}

.form-field {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: solid 0.5px fade(@custom-primary, 12%);
  background-color: white;
  line-height: 1.5rem;
  color: @custom_text-color;
  padding: 0.5rem 0.75rem;
  outline: none;
  font-size: @font-size-14;
  font-family:segoe-regular;
}

.form {
  &-container {
    max-width: 30.4rem;
    margin: 0 auto;
  }

  &-label {
    display: block;
    font-size: @font-size-16;
    font-family: segoe-bold;
    line-height: 1rem;
    margin-bottom: 0.5rem !important;
    color: @custom_text-color;
  }

  &-input,
  &-typeahead {
    input {
      .form-field();
    }
    input:focus {
      border: solid 0.5px @custom-primary;
      color: @custom_text-color;
    }

    select {
      .form-field();
      padding-top: 0;
      padding-bottom: 0;
    }
    select:focus {
      border: solid 0.5px @custom-primary;
      color: @custom_text-color;
    }
  }

  &-textarea {
    textarea {
      .form-field();
      height: 18rem;
      resize: none;
    }
  }

  .is-invalid-input {
    border-color: @error !important;
    background: fade(@error, 18%) !important;
    color: @error!important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    &::placeholder {
      color: @error!important;
    }
  }

  .pointer {
    cursor: pointer;
  }
}

.image-login{
  max-height: 90px;
}

.verify-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  .input-verify {
    width:2.5rem !important;
    height: 2.5rem !important;
    font-size: 1.5rem!important;
    font-family: genoe-bold;
    color: @custom_text-color;
    padding: 0;
  }

  .input-verify-error {
    padding: 0;
    border: 1px solid @error;
    color: @error;
  }
  .input-verify-success {
    padding: 0;
    border: 1px solid @custom-primary;
    color: @custom-primary;
  }

  @media @cellphone-only {
    padding: 1rem;
    max-width: 11rem;
    .input-verify {
      padding: 0;
    }
    .input-verify:nth-child(n + 4) {
      margin-top: 1rem;
    }
  }
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v95/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v94/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2) format('woff2');
}

.icons{
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons {
  font-family: "Material Icons";

  .icons();

  &-outlined{
    font-family: 'Material Icons Outlined';
    .icons();
  }
  &-round{
    font-family: 'Material Icons Round';
    .icons();
  }
}

.content{
  padding-top: 30px;
}

.card {
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
  @media @tablet-only {
    padding: 1.5rem 1.5rem;
  }

  @media @cellphone-only {
    padding: 1rem 1.5rem;
  }
}