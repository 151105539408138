/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: Raleway-light;
  src: url(/assets/fonts/Raleway/Raleway-Light.ttf) format('opentype');
}
@font-face {
  font-family: Raleway-SemiBold;
  src: url(/assets/fonts/Raleway/Raleway-SemiBold.ttf) format('opentype');
}
@font-face {
  font-family: Raleway-Regular;
  src: url(/assets/fonts/Raleway/Raleway-Regular.ttf) format('opentype');
}
@font-face {
  font-family: Raleway-Bold;
  src: url(/assets/fonts/Raleway/Raleway-Bold.ttf) format('opentype');
}
@font-face {
  font-family: segoe-italic;
  src: url(/assets/fonts/SegoeUIItalic.woff) format('opentype');
}
@font-face {
  font-family: segoe-bolditalic;
  src: url(/assets/fonts/SegoeUIBoldItalic.woff) format('opentype');
}
@font-face {
  font-family: segoe-light;
  src: url(/assets/fonts/segoeui_LIGHT.ttf) format('opentype');
}
@font-face {
  font-family: segoe-regular;
  src: url(/assets/fonts/segoeui_REGULAR.ttf) format('opentype');
}
@font-face {
  font-family: segoe-bold;
  src: url(/assets/fonts/segoeui/segoeuib.ttf) format('opentype');
}
@font-face {
  font-family: gidole-regular;
  src: url(/assets/fonts/Gidole-Regular.ttf) format('opentype');
}
body,
body .p-component {
  font-family: segoe-regular !important;
  margin: 0px !important;
  background-color: #fff;
}
body .p-toast .p-toast-message,
body .p-component .p-toast .p-toast-message {
  margin: 0 !important;
}
.navbar-toggler-icon {
  display: none;
}
.form-field {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: solid 0.5px rgba(17, 35, 75, 0.12);
  background-color: white;
  line-height: 1.5rem;
  color: #000000;
  padding: 0.5rem 0.75rem;
  outline: none;
  font-size: 0.875rem;
  font-family: segoe-regular;
}
.form-container {
  max-width: 30.4rem;
  margin: 0 auto;
}
.form-label {
  display: block;
  font-size: 1rem;
  font-family: segoe-bold;
  line-height: 1rem;
  margin-bottom: 0.5rem !important;
  color: #000000;
}
.form-input input,
.form-typeahead input {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: solid 0.5px rgba(17, 35, 75, 0.12);
  background-color: white;
  line-height: 1.5rem;
  color: #000000;
  padding: 0.5rem 0.75rem;
  outline: none;
  font-size: 0.875rem;
  font-family: segoe-regular;
}
.form-input input:focus,
.form-typeahead input:focus {
  border: solid 0.5px #11234B;
  color: #000000;
}
.form-input select,
.form-typeahead select {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: solid 0.5px rgba(17, 35, 75, 0.12);
  background-color: white;
  line-height: 1.5rem;
  color: #000000;
  padding: 0.5rem 0.75rem;
  outline: none;
  font-size: 0.875rem;
  font-family: segoe-regular;
  padding-top: 0;
  padding-bottom: 0;
}
.form-input select:focus,
.form-typeahead select:focus {
  border: solid 0.5px #11234B;
  color: #000000;
}
.form-textarea textarea {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: solid 0.5px rgba(17, 35, 75, 0.12);
  background-color: white;
  line-height: 1.5rem;
  color: #000000;
  padding: 0.5rem 0.75rem;
  outline: none;
  font-size: 0.875rem;
  font-family: segoe-regular;
  height: 18rem;
  resize: none;
}
.form .is-invalid-input {
  border-color: #dc3545 !important;
  background: rgba(220, 53, 69, 0.18) !important;
  color: #dc3545 !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form .is-invalid-input::placeholder {
  color: #dc3545 !important;
}
.form .pointer {
  cursor: pointer;
}
.image-login {
  max-height: 90px;
}
.verify-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.verify-container .input-verify {
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-size: 1.5rem !important;
  font-family: genoe-bold;
  color: #000000;
  padding: 0;
}
.verify-container .input-verify-error {
  padding: 0;
  border: 1px solid #dc3545;
  color: #dc3545;
}
.verify-container .input-verify-success {
  padding: 0;
  border: 1px solid #11234B;
  color: #11234B;
}
@media only screen and (min-width: 320px) and (max-width: 719px) {
  .verify-container {
    padding: 1rem;
    max-width: 11rem;
  }
  .verify-container .input-verify {
    padding: 0;
  }
  .verify-container .input-verify:nth-child(n + 4) {
    margin-top: 1rem;
  }
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2");
}
@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v95/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2) format('woff2');
}
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v94/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2) format('woff2');
}
.icons {
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.content {
  padding-top: 30px;
}
.card {
  padding: 2.5rem 2.5rem 1.5rem 2.5rem;
}
@media only screen and (min-width: 720px) and (max-width:1025px) {
  .card {
    padding: 1.5rem 1.5rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 719px) {
  .card {
    padding: 1rem 1.5rem;
  }
}
