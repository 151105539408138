@desktop-xlarge: ~'only screen and (min-width: 1400px)';
@desktop-large: ~'only screen and (min-width: 1200px)';
@desktop: ~'only screen and (min-width: 960px)';
@tablet: ~'only screen and (min-width: 720px)';
@cellphone: ~'only screen and (min-width: 320px)';
@cellphone-only: ~'only screen and (min-width: 320px) and (max-width: 719px)';
@tablet-only: ~'only screen and (min-width: 720px) and (max-width:1025px)';

@font-size-8: 0.5rem;
@font-size-10: 0.625rem;
@font-size-12: 0.75rem;
@font-size-13: 0.813rem;
@font-size-14: 0.875rem;
@font-size-16: 1rem;
@font-size-18: 1.125rem;
@font-size-20: 1.25rem;
@font-size-22: 1.375rem;
@font-size-24: 1.5rem;
@font-size-28: 1.7rem;
@font-size-32: 2rem;
@font-size-36: 2.25rem;
@font-size-40: 2.5rem;
@font-size-60: 3.75rem;
@font-size-base: 2rem;

@custom-primary:  #11234B;
@semi-light-custom-primary: #7b61ff4f;
@secondary-color:#c6c6c6;
@info-color:#099FD3 ;
@dark-grey:#666666;
@custom_text-color:#000000;
@error: #dc3545;
@supernova: #ffc107;
@blue: #00d7f1;
@grey:#767676;
@spinner-background: rgba(255, 255, 255, 0.35);
@success_color:#169540;
@help_color:#6f42c1;

@font-face {
  font-family: Raleway-light;
  src: url(/assets/fonts/Raleway/Raleway-Light.ttf) format('opentype');
}
@font-face {
  font-family: Raleway-SemiBold;
  src: url(/assets/fonts/Raleway/Raleway-SemiBold.ttf) format('opentype');
}
@font-face {
  font-family: Raleway-Regular;
  src: url(/assets/fonts/Raleway/Raleway-Regular.ttf) format('opentype');
}
@font-face {
  font-family: Raleway-Bold;
  src: url(/assets/fonts/Raleway/Raleway-Bold.ttf) format('opentype');
}
  @font-face {
    font-family: segoe-italic;
    src: url(/assets/fonts/SegoeUIItalic.woff) format('opentype');
  }
  
  @font-face {
    font-family: segoe-bolditalic;
    src: url(/assets/fonts/SegoeUIBoldItalic.woff) format('opentype');
  }

  @font-face {
    font-family: segoe-light;
    src: url(/assets/fonts/segoeui_LIGHT.ttf) format('opentype');
  }

  @font-face {
    font-family: segoe-regular;
    src: url(/assets/fonts/segoeui_REGULAR.ttf) format('opentype');
  }

  @font-face {
    font-family: segoe-bold;
    src: url(/assets/fonts/segoeui/segoeuib.ttf) format('opentype');
  }
  @font-face {
    font-family: gidole-regular;
    src: url(/assets/fonts/Gidole-Regular.ttf) format('opentype');
  }